<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <ion-grid fixed>
        <ion-row class="ion-justify-content-center">
          <ion-img
            style="height: 85px;width: 170px;"
            src="assets/icon/AAP_Logo_original_para-mail.png"
          ></ion-img>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-text>Mis Datos Productivos</ion-text>
        </ion-row>
        <ion-row
          class="ion-justify-content-center ion-align-items-center"
          style="margin-top: 24px"
        >
          <ion-col class="ion-align-self-center" size-md="6" size="12">
            <ion-card>
              <ion-card-header>
                <ion-row class="ion-justify-content-center">
                  <ion-card-title
                    size="4"
                    style="margin-top: 16px; text-align: center;"
                  >
                    Se ha cerrado sesión, ¡gracias por su visita!
                  </ion-card-title>
                  <ion-card-title size="4" style="margin-top: 32px" class="logearse ion-float-right" @click="$router.push('login')">
                    <b>Volver a logearse</b>
                  </ion-card-title>
                </ion-row>
              </ion-card-header>
              <ion-card-content></ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonPage,
  IonRow,
  IonImg,
  IonCard,
  IonCol
} from "@ionic/vue";
export default {
  components: {
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonPage,
    IonRow,
    IonImg,
    IonCard,
    IonCol
  }
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  #headerAAG {
    margin-left: 296px;
    margin-right: 296px;
    margin-top: 32px;
  }
  #tittle {
    margin-left: 104px;
  }
}
.logearse{
  color:  #96bb48;
  cursor: pointer;
}
</style>
